<template>
    <div class="card mb-4" v-show="previewPane" v-if="previewPane" :id="`previewPane-${previewPane.name}`">
        <div class="card-header" v-show="previewPane.header">
            Preview
        </div>
        <div class="card-body minh-180" >
            <div v-for="(dataItem, k) in dataPool" :key="k">
                <p class="d-flex mb-1 align-items-center preview-item" :class="{'content-error': dataItem.validAssetLength < 5 && dataItem.engine == 'media'}">
                <i class="las la-exclamation-circle text-primary mr-1 ml-0 pt-1" v-if="dataItem.validAssetLength < 5 && dataItem.engine == 'media'"></i>
                <span v-if="dataItem.engine == 'text'"> <VueMarkdown :source="dataItem.data" /></span>
                <span v-else-if="dataItem.engine == 'latex'" v-katex="{ expression: ' ' + dataItem.data, options: { throwOnError: false }}"></span>
                <span v-else>{{dataItem.data}}</span>
                <span class="ml-2 mr-0" :id="`previewPane-${previewPane.name}-toolbar-${k}`" >
                    <button class="txt-fade btn-custom color-blue" @click="previewOperation('edit', dataItem)" v-show="previewPane.editButton" v-if="dataItem.engine != 'media' || dataItem.validAssetLength < 5"> <i class="las la-pen"></i> </button>
                    <button class="txt-fade btn-custom text-primary"  @click="removeContent(dataItem, k)" v-show="previewPane.trashButton" > <i class="las la-trash"></i> </button>
                    <button class="txt-fade btn-custom"  @click="restoreContent(dataItem, k)" v-show="previewPane.restoreButton"> <i class="las la-undo-alt"></i> </button>
                </span>
                <span class="atbd-spin-dots spin-sm ml-1 no-display" :id="`previewPane-${previewPane.name}-loader-${k}`" >
                    <span class="spin-dot badge-dot dot-primary"></span>
                    <span class="spin-dot badge-dot dot-primary"></span>
                    <span class="spin-dot badge-dot dot-primary"></span>
                    <span class="spin-dot badge-dot dot-primary"></span>
                </span>
            </p>
            </div>
            
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown-render';
import { apiClient } from '@/plugins/restclient';
import EventBus from '@/plugins/eventbus';
import jQ from "jquery"
export default {
    components:{
        VueMarkdown
    },
    data(){
        return{
            contentMap: {
                question: 1,
                tip: 4,
                solution: 8
            },
            mediaAssetStatus: false,
            disableTrash:[]
        }
    },
    props:['previewPane', 'dataPool'],
    methods:{
        previewOperation(action, data){
            this.$emit('previewOperation', {from: 'previewPane', action, data })
        },
        removeContent(content,k){
            
            const buttonSelector = jQ(`#previewPane-${this.previewPane.name}-toolbar-${k} button`)
            const loaderSelector = jQ(`#previewPane-${this.previewPane.name}-loader-${k}`)

            loaderSelector.toggleClass("no-display")
            buttonSelector.prop('disabled', true)

            apiClient.patch('/v1/office/assessment/content/remove',{
                clid: content.clid
            })
            .then(()=>{
                // const buttonSelector = jQ(`#previewPane-${this.previewPane.name} .button:eq(${k})`).prop('disabled', false)
                // jQ(`#previewPane-${this.previewPane.name} .spin-sm:eq(${k})`).toggleClass("no-display")
                // const loaderSelector = `#previewPane-${this.previewPane.name} .spin-sm:eq(${k})`
                // const selector = `#previewPane-${this.previewPane.name} .preview-item:eq(${k})`
                // jQ(selector).toggleClass('no-display')
                // jQ(`#previewPane-${this.previewPane.name} .spin-sm:eq(${k})`).toggleClass("no-display")
                // console.log(k, jQ(`#previewPane-${this.previewPane.name} .preview-item:eq(${k})`), 'remove')
                // jQ(`#previewPane-${this.previewPane.name} .preview-item:eq(${k})`).toggleClass("no-display")
                setTimeout(()=>{

                    EventBus.$emit('contentOperations', {action: 'delete', contentId: this.previewPane.clid, contentType:this.previewPane.contentType, loaderSelector, buttonSelector})
                },3000)
            })
            .catch(err=>{
                // jQ(`#previewPane-${this.previewPane.name} .spin-sm:eq(${k})`).toggleClass("no-display")
                jQ(`#previewPane-${this.previewPane.name} .spin-sm:eq(${k})`).prop('disabled', true)
                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000})
            })
        },
        restoreContent(content, k){

            let url = ''
            let reqBody = ''

            if(content.alid){
                url = 'v1/office/assessment/answer/restore'
                reqBody = {
                    alid: content.alid
                }
            }else{
                url = '/v1/office/assessment/content/restore'
                reqBody = {
                    clid: content.clid
                }
            }
            
            const buttonSelector = jQ(`#previewPane-${this.previewPane.name}-toolbar-${k} button`)
            const loaderSelector = jQ(`#previewPane-${this.previewPane.name}-loader-${k}`)

            loaderSelector.toggleClass("no-display")
            buttonSelector.prop('disabled', true)

            apiClient.patch(url, reqBody)
            .then(()=>{
                
                if(content.alid){
                    this.$emit('answerOperations', {action: 'add' })
                }else{
                    
                    this.$emit('contentOperations', {action: 'add', contentId:this.previewPane.clid, contentType: this.contentMap[this.previewPane.contentType], loaderSelector, buttonSelector})
                    
                }
                
            })
            .catch(err =>{
                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000})
            })
        }
    }
}
</script>
<style>
.content-error{
    font-size: 14px;
    background: #ffb8b838;
    padding: 2px 13px;
    border-radius: 5px;
    color: #d87777;
}
.btn-custom{
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px 5px;
    border-radius: 50%;
    opacity: .5;
    border:none
}
.btn-custom:disabled{
    opacity: .3;
    cursor: wait;
}
.btn-custom:disabled:hover{
    opacity: .3;
}
.btn-custom:hover{
    opacity: .8;
}
</style>