<template>
    <div class="mb-5">
       <PreviewPane :previewPane="previewPane" :dataPool="config.data" @previewOperation="previewPaneAction"/>
        <!-- Editor -->
        <div :class="{'card': editorPane.wrap}">
            <div class="card-header" v-show="editorPane.header">
                Editor
            </div>
            <div :class="{'card-body': editorPane.wrap }">
                <div class="atbd-nav-controller">
                    <div class="btn-group atbd-button-group btn-group-normal nav" role="tablist">
                        <a :href="`#${config.name}-content-text`" class="btn btn-sm btn-outline-light nav-link active" @click="content.engine = 'text'" data-toggle="tab" role="tab" aria-controls="default" aria-selected="true">Text</a>
                        <a :href="`#${config.name}-content-latex`" class="btn btn-sm btn-outline-light nav-link"  @click="content.engine = 'latex'" data-toggle="tab" role="tab" aria-controls="default" aria-selected="false">Latex</a>
                        <a :href="`#${config.name}-content-media`" class="btn btn-sm btn-outline-light nav-link"  @click="content.engine = 'media'" data-toggle="tab" role="tab" aria-controls="default" aria-selected="false">Media</a>
                        
                    </div>
                </div>
                <div class="tab-content text-left">
                    <div class="tab-pane fade show active" :id="`${config.name}-content-text`" role="tabpanel" aria-labelledby="size-large">
                        <div class="card-body p-0 mt-3" :class="{'error':$v.editor.markdown.$error}" style="z-index:9999; position:relative">
                            <vue-simplemde v-model.trim="$v.editor.markdown.$model" :ref="`${config.name}-smde`" :configs="editorConfig" :autoinit="true" />
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" :id="`${config.name}-content-latex`" role="tabpanel" aria-labelledby="size-large">
                        <a class="btn btn-outline-primary mt-3" href="https://latexeditor.lagrida.com/" target="_blank">Compose Formula</a>
                        <div class="card-body px-0 pt-2">
                            <div class="d-flex">
                                <div class="flex-1 mr-3 ml-0" :class="{'error':$v.editor.latex.$error}">
                                    <div class="form-group mb-0">
                                      <textarea class="form-control h-180" name="" id="" rows="3" placeholder="Enter latex" v-model.trim="$v.editor.latex.$model"></textarea>
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <div class="note-card note-default paddCard h-180">
                                        <div v-katex="{ expression: ' ' + editor.latex, options: { throwOnError: false }}"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :id="`${config.name}-content-media`" role="tabpanel" aria-labelledby="size-large">
                        <div class="card-body p-0 mt-3">
                            <div>
                                <a class="btn btn-outline-primary mt-3" href="https://www.autodraw.com/" target="_blank">Create Graphics</a>
                                <div class="form-group pt-2">
                                    <label for="">Media Tag</label>
                                    <div class="d-flex flex-1">
                                        <input type="text" :disabled="mediaRef"
                                    class="form-control form-control-md mr-2 ml-0" :class="{'error': $v.editor.media.$error }" v-model.trim="$v.editor.media.$model" placeholder="">
                                    <span @click="addContent">
                                        <spinner-button label="Add" klass="btn-sm" :loading="loading.addMediaTag" :disabled="mediaRef" />
                                    </span>
                                    </div>
                                </div>
                                <div class="form-group" v-show="mediaRef">
                                    <div class="d-flex">
                                        <div class="col-md-6 pl-0 pr-2">
                                            <div :class="{'error': $v.media.position.$error}">
                                                <v-select v-model.trim="$v.media.position.$model" :options="selectOptions.position" :reduce="position => position.value" item-text="label" item-value="value" label="label"></v-select>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-6 pl-2 pr-0">
                                            <div :class="{'error': $v.media.scale.$error}">
                                                <v-select v-model.trim="$v.media.scale.$model" :options="selectOptions.size" :reduce="size => size.value" item-text="label" item-value="value" label="label"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="atbd-upload__button mt-3 d-flex align-items-center">
                                            <a href="javascript:void(0)" class="btn btn-lg btn-outline-lighten btn-upload" @click="fileSelector(config.name)"> <span data-feather="upload"></span> Select File</a>
                                            <input  type="file" :name="`${config.name}-media`" :id="`${config.name}-media`" accept=".jpg,.png" class="d-none" @change="fileChange(`${config.name}-media`)">
                                            <a href="#" class="file-name ml-3"><i class="las la-paperclip"></i> <span class="name-text">{{fileDataHandler.file && fileDataHandler.file.name || 'No File' }}<span></span></span></a>
                                            <span v-show="$v.media.data.$error">
                                                <i class="fa fa-exclamation-circle text-primary"></i>
                                                <span class="ml-2 text-primary">Error</span>
                                            </span>
                                        </div>

                                        <small></small>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Modal -->
                    <!-- <div class="modal fade" :id="`${config.name}-upload-media`" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Upload Media</h5>
                                        <button type="button" class="close"  @click="closeModal(name + '-upload-media')" aria-label="Close" >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                </div>
                                <div class="modal-body" >
                                    <form action="">
                                        <div class="form-group">
                                          <label for="">Image Tag</label>
                                          <div class="d-flex flex-1">
                                              <input type="text"
                                            class="form-control mr-2 ml-0" name="" id="" aria-describedby="helpId" placeholder="">
                                          <button type="button" class="btn btn-primary btn-sm" role="button">Add</button>
                                          </div>
                                          
                                          <small id="helpId" class="form-text text-muted">Help text</small>
                                        </div>
                                        <div class="row" v-show="media.ref">
                                            <div class="col-md-6">
                                                <v-select v-model="media.position"></v-select>
                                            </div>
                                            <div class="col-md-6">
                                                <v-select v-model="media.size"></v-select>
                                            </div>
                                            <div class="atbd-upload__button mt-3 ml-3">
                                                <a href="javascript:void(0)" class="btn btn-lg btn-outline-lighten btn-upload" onclick="$('#upload-2').click()"> <span data-feather="upload"></span> Upload</a>
                                                <input type="file" name="image" class="upload-one no-display" id="image">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary">Upload</button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="card-footer text-muted ">
                <span @click="content.engine == 'media' ? addMedia() : addContent()" style="display:inline-block">
                    <spinner-button :label="content.engine == 'media' ? 'Upload' : 'Add' " :loading="loading.addContent" />
                </span>
                
            </div>
        </div>
    </div>
</template>
<script>
import { apiClient } from '@/plugins/restclient';
import SpinnerButton from '../SpinnerButton.vue';
import fileToBase64 from '@/mixins/fileToBase64';
import { required}  from 'vuelidate/lib/validators';
import jQ from "jquery";
// import VueMarkdown from 'vue-markdown-render';
import PreviewPane from '../qbuilder/PreviewPane.vue';

export default {
  components: { SpinnerButton, PreviewPane },
    props:['previewPane', 'editorPane', 'config'],
    data(){
        return{
            mediaRef: null,
            mediaName: '',
            media:{
                owner: '',
                type: this.config.type,
                name: '',
                position: '',
                scale: '',
                data: '',
            },
            content:{
                owner: this.config.contentId,
                type: this.config.type,
                data: this.editorContent,
                engine: 'text',
            },
            selectOptions:{
                size:[
                    {label: 'Small', value: 'small'},
                    {label: 'Medium', value: 'medium'},
                    {label: 'Large', value: 'large'},
                ],
                position:[
                    {label: 'Inline', value: 'inline'},
                    {label: 'New Line', value: 'newline'}
                ]
            },
            editor:{
                markdown:'',
                latex: '',
                media: ''
            },
            fileDataHandler:'',
            loading:{
                addContent: false,
                addMediaTag: false
            },
            editorConfig:{
                forceSync: true,
                placeholder: 'Type here...',
            }
        }
    },
    watch:{
        'config.data': function(newData){
            if(newData.handle == 'answer'){
                this.editContent(newData)
            }
        }
    },
    mixins:[fileToBase64],
    methods:{
        fileSelector(name){
            jQ('#' + name + '-media').click()
        },
        prepContent(){
            const engine = this.content.engine
            if(engine == 'text'){
                this.content.data = this.editor.markdown
                return
            }else if(engine == 'media'){
                this.content.data = `@${this.editor.media}@`
                return
            }else{
                this.content.data = this.editor.latex
            } 
        },
        addAnswer(){
            apiClient.post('/v1/office/assessment/answer/add',{
                content:{
                    data: this.content.data,
                    engine: this.content.engine,
                },
                qlid: this.config.contentId
            })
            .then((res)=>{
                
                // const answer = {
                //     ...res.data.info,
                //     engine: this.content.engine,
                //     data: this.content.data,
                //     status: 1,
                // }
                if(this.loading.addMediaTag){
                    
                    this.mediaRef = res.data.info.alid
                    this.mediaName = res.data.info.refs.pop()
                }
                this.loading.addMediaTag = false
                if(this.content.engine == 'text'){
                    this.$refs[`${this.config.name}-smde`].simplemde.codemirror.setValue('')
                    this.editor.markdown = ''
                    this.$v.editor.markdown.$reset()
                }else if(this.content.engine == 'latex'){
                    this.editor.latex = ''
                    this.$v.editor.latex.$reset()
                }
                let engine = ""
                if(this.content.engine  == 'media'){
                    engine = "media"
                }
                this.loading.addContent = false
                this.$emit('answerOperations', {action: 'add', engine})

            })
            .catch(err =>{
                this.loading.addContent = false
                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000})
            })
        },
        addContent(){
            this.prepContent()

            this.content.owner = this.config.contentId
            this.$v.$reset()
            if(this.content.engine == 'media'){
                this.$v.editor.media.$touch()
                if(this.$v.editor.media.$invalid){
                    return
                }
                this.loading.addMediaTag = true
            }else if(this.content.engine == 'text'){
                this.$v.editor.markdown.$touch()
                if(this.$v.editor.markdown.$invalid){
                    return
                }
                this.loading.addContent = true
            }else{
                this.$v.editor.latex.$touch()
                if(this.$v.editor.latex.$invalid){
                    return
                }
            }
            if(this.content.type == 2){
                this.addAnswer()
                return
            }
            
            apiClient.post('/v1/office/assessment/content/add', this.content,{

            })
            .then(res =>{
                this.loading.addContent = false

                this.$emit('contentOperations', {action: 'add', contentType: this.content.type, contentId:this.config.contentId})
                if(this.content.engine == 'text'){
                    this.$refs[`${this.config.name}-smde`].simplemde.codemirror.setValue('')
                    this.editor.markdown = ''
                    this.$v.editor.markdown.$reset()
                }else if(this.content.engine == 'latex'){
                    this.editor.latex = ''
                    this.$v.editor.latex.$reset()
                }

                if(this.loading.addMediaTag){
                    
                    this.mediaRef = res.data.info.clid
                    this.mediaName = res.data.info.refs.pop()
                }
                this.loading.addMediaTag = false
                
            })
            .catch(err =>{
                this.loading.addContent = false
                
                this.loading.addMediaTag = false

                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000 })
            })
        },
        editContent(content){
            this.$v.$reset()
            if(content.engine == 'text'){
                this.editor.latex = ''
                this.editor.media = ''
                setTimeout(()=>{
                    this.editor.markdown = content.data
                },300)
                
                Window.trigger('click', '[href="#'  +  this.config.name + '-content-text"]')
            }else if(content.engine == 'latex'){
                this.editor.markdown = ''
                this.editor.media = ''
                setTimeout(()=>{
                    this.editor.latex = content.data
                },300)
                
                Window.trigger('click', '[href="#'  + this.config.name + '-content-latex"]')
            }else{
                this.editor.markdown = ''
                this.editor.latex = ''
                // console.log(content)
                setTimeout(()=>{
                    this.editor.media = content.data
                    this.mediaName = this.editor.media.replace(/@/g, '')
                },300)
                
                this.mediaRef = content.clid
                this.content.engine = 'media'
                
                Window.trigger('click', '[href="#' + this.config.name + '-content-media"]')
            }
            
        },
        addMedia(){
            this.media.data = this.fileDataHandler.url
            this.media.owner = this.mediaRef
            this.media.name = this.mediaName

            this.$v.mediaMeta.$touch()
            if(this.$v.mediaMeta.$invalid){
                return
            }
           
            this.loading.addContent = true
            apiClient.post('/v1/office/assessment/asset/add',this.media,{

            })
            .then(()=>{
                this.loading.addContent = false
                // this.$v.mediaForm.$clear()
                this.media.scale = ''
                this.media.data = ''
                this.media.name = ''
                this.media.position = ''
                this.media.owner = ''
                this.editor.media = ''
                this.mediaRef = null
                this.$v.mediaForm.$reset();
                this.$v.editor.media.$reset();
                if(this.config.type == 1){
                    this.$emit('contentOperations', {action:'add', contentType: 1, contentId: this.config.contentId})
                }else if(this.config.type == 2){
                    this.$emit('answerOperations', {action:'add', contentType: 2, mediaAdd: true, contentId: this.config.contentId})
                }
                
                

                
                
            })
            .catch(err =>{
                this.loading.addContent = false
                this.$store.commit('ctoast', {type: 'danger', message: err.response.data.message, timeout: 7000 })
            })
        },
        previewPaneAction(operation){
            if(operation.action == 'edit'){
                this.editContent(operation.data)
            }
        },
        closeModal(name){
            Window.closeVueBsModal(name)
        }
    },
    validations:{
        media:{
            type: {
                required
            },
            name: {
                required,
            },
            position: {
                required
            },
            scale: {
                required
            },
            data:{
                required,
            },            
        },
        mediaMeta:['media.data', 'media.scale', 'media.position' ],
        mediaForm: ['media.data', 'media.scale', 'media.position', 'type', 'name' ],
        editor:{
            markdown: {
                required
            },
            latex:{
                required
            },
            media:{
                required
            }
        },
        content:{
            data: {
                required
            }
        },
    }
};
</script>
<style>
.h-180{
    height: 180px !important;
}
p{
   margin-bottom: 0.25rem !important;
}

</style>